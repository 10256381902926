<template>
	<div style="overflow-y: auto; width: 100%; height: 100%">
		<div style="display: flex; position: sticky; top: 0" v-if="widgetData.items.length > 0">
			<v-hover  v-for="(c, k) in widgetData.items[0].values" :key="k" v-slot:default="{ hover }">
				<v-col class="text-truncate secondary" style="min-width: 100px;">
					<v-row class="mx-0 flex-nowrap" align="center" no-gutters>
						<v-col class="text-truncate text-left">{{ k }}</v-col>
						<v-col cols="auto" class="pl-2" style="min-width: 20px">
							<v-icon @click="reorder(k, descendant); descendant = !descendant" v-show="hover" size="12" color="white" class="cursor-pointer">mdi-arrow-up</v-icon>
						</v-col>
					</v-row>
				</v-col>
			</v-hover>
		</div>
		<div style="display: flex" class="flex-nowrap black2white--text" v-for="(r, i) in widgetData.items" :key="i">
			<v-col :class="i % 2 === 0 ? '':'cell2'" class="text-truncate text-left" style="min-width: 100px;" :style="i !== widgetData.items.length - 1 ? {borderBottom: '1px solid var(--v-cell2-base)'}:{}" v-for="(c, j) in r.values" :key="j">
				{{ c }}
			</v-col>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Table",
		props: ['widgetData'],
		data() {
			return {
				descendant: false
			}
		},
		methods: {
			reorder(key, des){
				this.widgetData.items.sort((item1, item2)=> {
					if (!des){
						if (item1.values[key] === null)
							return 1
						if (item2.values[key] === null)
							return -1
					}
					else {
						if (item2.values[key] === null)
							return 1
						if (item1.values[key] === null)
							return -1
					}
					if (!isNaN(parseFloat(item1.values[key])) && !isNaN(parseFloat(item2.values[key]))){
							this.compareNumbers(item1,item2, des)
					}
					else if (!des){
						return item1.values[key].localeCompare(item2.values[key], this.$store.getters["profile/user_language"].locale, { sensitivity: 'base' })
					}
					else {
						return item2.values[key].localeCompare(item1.values[key], this.$store.getters["profile/user_language"].locale, { sensitivity: 'base' })
					}
					
				})
			},
			compareNumbers(a, b, des){
				if (!des)
					return a-b
				else
					return b-a
			}
		},
	}

</script>

<style scoped>

</style>
